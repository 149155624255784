<template>
    <div class="blog-detail">
      <!-- Hero Banner Section -->
      <v-layout column>
        <v-flex xs12>

          <div v-if="blogview.images" class="hero-banner1 hidden-sm-and-up">
            <v-img
              :src="mediaURL + blogview.images[0]"
              height="800px"
              
            >
              <!-- Overlay Content -->
              <v-layout
               
                align-center
                justify-center
                fill-height
                class="text-center pa-4 overlay-content"
              >
                <v-flex xs12>
                  <h1 class="white--text font-weight-bold text-shadow">
                    {{ blogview.title }}
                  </h1>
                </v-flex>
              </v-layout>
            </v-img>
          </div>

          <div v-if="blogview.images" class="hero-banner hidden-xs-only" :style="`background-image: url('${mediaURL + blogview.images[0]}')`">
            <v-layout column align-center justify-center fill-height class="text-center pa-4">
              <v-flex xs12 sm8 md6 pt-16>
                <h1 style="" class="white--text font-weight-bold">{{ blogview.title }}</h1>
               
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
      </v-layout>
  
      <!-- Content Section -->
      <v-layout justify-center>
        <v-flex xs12 class="pa-2">
          <!-- About Section -->
          <!-- <div class="about-section mt-8"> -->
            <v-layout wrap justify-center class="my-3">
                <v-flex py-4>
                    <span class="itemHeading">
                        {{ blogview.shortDiscription }} 
                    </span>
                </v-flex>
            </v-layout>
            <v-layout wrap justify-center class="my-3">
                <v-flex xs12>
                    <span style="font-family:kumbhMedium" class="pageSize" v-html="blogview.content"></span>
                </v-flex>
            </v-layout>
          <!-- </div> -->
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
    
        blogview:{},
    
       
      };
    },
    mounted(){
this.viewBlog();
    },
    methods: {
        viewBlog() {
            this.appLoading = true;
            axios({
                url: "/employee/blog/view/" + this.$route.query.id,
                method: "GET",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
                //   params: {
                //     limit: this.limit,
                //     page: this.currentPage,
                //     username: this.search,
                //   },
            })
                .then((response) => {
                    this.appLoading = false;
                    this.blogview = response.data.data;
                    //this.pages = Math.ceil(response.data.totalLength / this.limit);
                })
                .catch((err) => {
                    this.appLoading = false;
                    (this.ServerError = true), console.log(err);
                });
        },
    },
   
  };
  </script>
  
  <style scoped>
  .hero-banner {
    height: 60vh;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
  }
  
 
  @media (max-width: 600px) {
    .hero-banner {
      height: 40vh;
    }
  }
  </style>
  <style>
.pageSize p {
  display: flex;
  align-items: center;
  text-align: left; /* Aligns text to the left */
}

.pageSize p img {
  height: 50%;
  width: 50%!important;
  display: block; /* Ensures the image is treated as a block element */
  margin: auto; /* Centers the image horizontally */
}
.hero-banner1 {
  position: relative;
}

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Optional: Adds a dark overlay for better text visibility */
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}
</style>